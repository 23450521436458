import React, { FunctionComponent, useEffect, useState, useContext } from 'react'
import { graphqlClient } from 'utils/graphqlClient'
import { Button, Heading, Icon, Text } from '@plurall/elo'
import { Card, Loading, Table } from 'components'
import { userTasksDisciplinesQuery } from 'graphql/queries'
import { getTableColumns } from './Columns'
import styles from './Details.module.css'
import { TaskType, TaskTypeDiscipline } from 'types/enums'
import { ContextFormat, FilterContext } from 'utils/context'
import { exportReportXLSX, EXPORT_STATUS_MESSAGE, getFormattedDate } from 'utils'

import Client from 'utils/restClient'
import { useToastInModal } from 'hooks/useToastInModal'

const clientRest = new Client()

interface DetailsProps {
  filters: any
  studentId: number
  studentName: string
}

interface UserTaskService {
  data: {
    me: {
      userTasks: {
        disciplines: Discipline[]
        taskTypes: TaskType[]
      }
    }
  }
}

interface ObjectiveQuestions {
  done: number
  correct: number
  total: number
}

interface Discipline {
  id: number
  discipline: { id: string; name: string }
  discursiveQuestions: { done: number; total: number }
  essay: { done: number; total: number }
  objectiveQuestions: ObjectiveQuestions
  readingActivities: { done: number; total: number }
  videos: { watched: number; total: number }
}

interface CheckboxFilters {
  key: keyof typeof TaskTypeDiscipline
  visible: boolean
}

const Details: FunctionComponent<DetailsProps> = ({ filters, studentId, studentName }) => {
  const { setToast, renderToast } = useToastInModal()
  const context = useContext(ContextFormat)
  const contextFormat = context && context.length > 0 ? context : [filters.contextFormat]
  const [userDetails, setUserDetails] = useState<Discipline[]>([])
  const { selectedCheckboxFilters } = useContext(FilterContext)
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [exportLoading, setExportLoading] = useState(false)

  useEffect(() => {
    getStudentDetails(studentId, filters, contextFormat).then(
      ({
        data: {
          me: { userTasks },
        },
      }) => {
        setUserDetails(handleUserDetailsFilter(userTasks.disciplines))
        setTaskTypes(handleTaskTypesFilter(userTasks.taskTypes))
        setLoading(false)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, studentId, contextFormat])

  const handleUserDetailsFilter = (disciplines: Discipline[]) => {
    return disciplines.filter((discipline: Discipline) => {
      if (
        selectedCheckboxFilters.some(({ key, visible }: CheckboxFilters) => {
          return visible && discipline[TaskTypeDiscipline[key]].total > 0
        })
      ) {
        return discipline
      }
      return null
    })
  }

  const handleTaskTypesFilter = (tasks: string[]) => {
    return selectedCheckboxFilters
      .filter((checkbox: CheckboxFilters) => checkbox.visible && tasks.includes(checkbox.key))
      .map((checkbox: CheckboxFilters) => checkbox.key)
  }

  const handleExportDetails = async () => {
    window.PLURALL_TRACKER.track('students:students-tab:disciplines-tab:export')

    setToast({ show: true, message: EXPORT_STATUS_MESSAGE.LOADING, variant: 'informative' })
    try {
      setExportLoading(true)
      const params = {
        ...filters,
        contextFormat,
        taskType: [
          TaskType.MultipleChoice,
          TaskType.OpenResponse,
          TaskType.Read,
          TaskType.Video,
          TaskType.Essay,
        ],
        userId: studentId,
      }

      const query = userTasksDisciplinesQuery?.loc?.source.body
      const operationName = 'userTasks'

      const { request } = await clientRest.fetchReportExport(params, query, operationName)

      const options = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }
      const date = new Date()

      const name = `Desempenho_Disciplinas-${studentName}-${getFormattedDate(date, options)}`

      setToast({ show: true, message: EXPORT_STATUS_MESSAGE.SUCCESS, variant: 'positive' })

      exportReportXLSX(request?.response, name)
    } catch (error) {
      setToast({ show: true, message: EXPORT_STATUS_MESSAGE.ERROR, variant: 'negative' })
    } finally {
      setExportLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return renderDetails(taskTypes, userDetails, handleExportDetails, renderToast, exportLoading)
}

const renderDetails = (
  taskTypes: TaskType[],
  userDetails: any[],
  handleExportDetails: () => void,
  renderToast: () => JSX.Element | undefined,
  exportLoading: boolean,
) => (
  <>
    {renderToast()}
    <div className={styles.detailTabContent}>
      <div className={styles.tableTitle}>
        <Heading size='small'>Desempenho por disciplinas</Heading>
      </div>
      <Card>
        <div className={styles.tableCaptionWrapper}>
          <div className={styles.tableCaption}>
            <Text bold secondary element='span'>
              Legenda:
            </Text>
            <span className={styles.tableCaptionIcon}>
              <Icon type='statsPositive' size='small' />
            </span>
            <Text secondary element='span'>
              Acima de 50%
            </Text>
            <span className={styles.tableCaptionIcon}>
              <Icon type='statsNegative' size='small' />
            </span>
            <Text secondary element='span'>
              Abaixo de 50%
            </Text>
          </div>
        </div>
        <Table
          columns={getTableColumns(taskTypes)}
          data={userDetails}
          headerClass={styles.header}
        />
      </Card>
    </div>

    <div className={styles.export}>
      <div className={styles.button}>
        <Button
          onClick={handleExportDetails}
          data-test-id={'button-export-details'}
          disabled={!userDetails?.length || exportLoading}
        >
          Exportar
        </Button>
      </div>
    </div>
  </>
)

const getStudentDetails = (
  studentId: number,
  filters: any,
  contextFormat: string[],
): Promise<UserTaskService> =>
  graphqlClient.query({
    query: userTasksDisciplinesQuery,
    variables: {
      ...filters,
      contextFormat,
      taskType: [
        TaskType.MultipleChoice,
        TaskType.OpenResponse,
        TaskType.Read,
        TaskType.Video,
        TaskType.Essay,
      ],
      userId: studentId,
    },
  })

export default Details
